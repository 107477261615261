import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import UserListPageHeader from "./UserListPageHeader";
import {DataGrid} from "@mui/x-data-grid";
import {columnsUserListDescription} from "../../../Descriptions/userListDescription";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Paper,
    Stack,
    Tooltip
} from "@mui/material";
import {getMessageDescriptor} from "../../../../../../i18n/messages";
import {useDispatch, useSelector} from "react-redux";
import {getUserSelector} from "../../../../../../store/selectors/usersSelectors";
import {deleteUser, retrieveAllUsersFiltered} from "../../../../../../corelogic/usecase/user/userActions";
import Box from "@mui/material/Box";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import RefreshIcon from "@mui/icons-material/Refresh";
import ReorderIcon from "@mui/icons-material/Reorder";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {useNavigate} from "react-router-dom";
import {getUserLoggedSelector} from "../../../../../../store/selectors/sessionSelector";
import {DividingHR} from "../../../Utils/DividingHR";
import {UserStatusV2} from "../../../../../secondary/InMemory/Data/enums";
import {UserFilter, UserRoleUtils} from "../../../../../../corelogic/models/user";

const UserListPage = () => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const navigation = useNavigate()
    const {data, fetching, count} = useSelector(getUserSelector)
    const {userLogged} = useSelector(getUserLoggedSelector)
    const [userFilter, setUserFilter] = useState<UserFilter>({
        search: "",
    })
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 25,
        page: 0,
    })
    const [density, setDensity] = useState(false)
    const [deletionModale, setDeletionModale] = useState(false)
    const [userIdForDeletion, setUserIdForDeletion] = useState<number>()
    const columns: GridColDef[] = [
        ...columnsUserListDescription.map(cd => ({
            ...cd,
            headerName: intl.formatMessage(getMessageDescriptor(cd.headerName)),
            description: intl.formatMessage(getMessageDescriptor(cd.headerName))
        })),
        {
            field: 'actions',
            type: 'actions',
            headerName: 'bic',
            width: 150,
            headerAlign: "center",
            sortable: false,
            editable: false,
            filterable: false,
            disableReorder: true,
            resizable: false,
            hideable: false,
            renderHeader: () => {
                return (
                    <Box sx={{width: "100%", height: "100%"}}>
                        <Tooltip title="Rafraîchir le tableau">
                            <IconButton onClick={handleRefresh}
                                        sx={{
                                            fontSize: "1.5rem",
                                            cursor: "pointer"
                                        }}>
                                <RefreshIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Changer la densité">
                            <IconButton onClick={handleChangeDensity}
                                        sx={{
                                            fontSize: "1.5rem",
                                            cursor: "pointer"
                                        }}>
                                <ReorderIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )
            },
            renderCell: (params) => {
                return (
                    <Box sx={{display: "flex", justifyContent: "space-between"}}>
                        <Tooltip title="Editer ce user">
                            <IconButton onClick={() => navigation(`/users/${params.row.id}`)}
                                        disabled={!UserRoleUtils.isSodiwin(userLogged) && UserRoleUtils.isSodiwin(params.row)}
                                        sx={{
                                            fontSize: "1.5rem",
                                            cursor: "pointer"
                                        }}>
                                <EditIcon
                                    sx={{fill: !UserRoleUtils.isSodiwin(userLogged) && UserRoleUtils.isSodiwin(params.row) ? "grey" : "rgba(33, 150, 243, 1)"}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Supprimer ce user">
                            <IconButton onClick={() => handleDeleteUserClick(params.row.id)}
                                        disabled={userLogged?.id === params.row.id || params.row.status.value === UserStatusV2.INACTIVE || UserRoleUtils.isSodiwin(params.row)}
                                        sx={{
                                            fontSize: "1.5rem",
                                            cursor: "pointer"
                                        }}>
                                <DeleteIcon
                                    sx={{fill: userLogged?.id === params.row.id || params.row.status.value === UserStatusV2.INACTIVE || UserRoleUtils.isSodiwin(params.row) ? "grey" : "rgba(255, 82, 82, 1)"}}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )
            }
        }
    ]

    useEffect(() => {
        dispatch(retrieveAllUsersFiltered(userFilter, {page: paginationModel.page + 1, size: paginationModel.pageSize}))
    }, [dispatch, paginationModel.page, paginationModel.pageSize, userFilter])

    const handleRefresh = useCallback(() => {
        dispatch(retrieveAllUsersFiltered(userFilter, {page: paginationModel.page + 1, size: paginationModel.pageSize}))
    }, [dispatch, userFilter, paginationModel.page, paginationModel.pageSize])

    const handleDeleteUserClick = (userId: number) => {
        setDeletionModale(true)
        setUserIdForDeletion(userId)
    }

    const handleConfirmDeletion = () => {
        setDeletionModale(false)
        if (userIdForDeletion) {
            dispatch(deleteUser(userIdForDeletion))
        }
    }

    const handleCancelDeletion = () => {
        setDeletionModale(false)
    }

    const handleChangeDensity = () => {
        setDensity(prevState => !prevState)
        if (!density && paginationModel.pageSize <= 50) {
            setPaginationModel({...paginationModel, pageSize: 50})
        }
    }

    const renderDeleteModale = () => {
        return (
            <Dialog
                open
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {intl.formatMessage(getMessageDescriptor("userDeletionInfoTitle"))}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText m={1}>
                        <Alert
                            severity="warning"
                            color="info">{intl.formatMessage(getMessageDescriptor("userDeletionWarningConfirm"))}</Alert>
                    </DialogContentText>
                    <DialogContentText m={1}>
                        <Alert
                            severity="info"
                            color="error">{intl.formatMessage(getMessageDescriptor("userDeletionWarningCancel"))}</Alert>
                    </DialogContentText>
                </DialogContent>
                <DividingHR subContent/>
                <DialogActions>
                    <Button onClick={handleCancelDeletion} color="error"
                            variant="outlined">{intl.formatMessage(getMessageDescriptor("genericCancel"))}</Button>
                    <Button onClick={handleConfirmDeletion} autoFocus
                            variant="outlined">{intl.formatMessage(getMessageDescriptor("genericConfirm"))}</Button>
                </DialogActions>
            </Dialog>)
    }

    return (
        <>
            <div>
                <Paper elevation={3}
                       sx={{position: "sticky", top: "77px", zIndex: 1000, backgroundColor: "common.main"}}>
                    <UserListPageHeader counterUsers={count}/>
                </Paper>
                <Box sx={{height: "84vh", width: '100%', padding: "10px 10px 10px 10px"}}>
                    <Paper sx={{height: "100%"}}>
                        <DataGrid
                            rows={data}
                            rowCount={count}
                            paginationMode="server"
                            loading={fetching}
                            columns={columns}
                            columnVisibilityModel={{id: false}}
                            paginationModel={paginationModel}
                            pageSizeOptions={density ? [50, 75, 100] : [25, 50, 75, 100]}
                            onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
                            density={density ? "compact" : "standard"}
                            disableRowSelectionOnClick
                            slots={{
                                noRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        <Alert severity="info">
                                            {intl.formatMessage(getMessageDescriptor("itemListFilterNotFound"))}
                                        </Alert>
                                    </Stack>
                                )
                            }}
                            sx={{
                                "& .MuiDataGrid-cell:focus": {
                                    outline: "none"
                                },
                                "& .MuiDataGrid-columnHeader:focus": {
                                    outline: "none"
                                }
                            }}
                        />
                    </Paper>
                </Box>
            </div>
            {deletionModale && renderDeleteModale()}
        </>
    )
}

export default UserListPage;