import React, {useCallback, useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Tooltip
} from "@mui/material";
import {DataGrid, GridRenderCellParams, GridRowParams, GridRowSelectionModel} from "@mui/x-data-grid";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {useNavigate, useSearchParams} from "react-router-dom";
import RefreshIcon from '@mui/icons-material/Refresh';
import ReorderIcon from '@mui/icons-material/Reorder';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomerHeader from "../CustomerHeader";
import {
    deleteCustomer,
    retrieveAllCustomersFiltered,
    retrieveCustomerFormDataValues
} from "../../../../../../corelogic/usecase/customer/customersActions";
import {useDispatch, useSelector} from "react-redux";
import {getCustomerFormSelector, getCustomersSelector} from "../../../../../../store/selectors/customersSelector";
import {APP_ACTIONS_TYPES} from "../../../../../../store/AppActionsTypes";
import EditIcon from '@mui/icons-material/Edit';
import {CustomerFilter, CustomerFilterImpl} from "../../../../../../corelogic/models/customer";
import {getColumnsCustomersListDescription} from "../../../Descriptions/customersListDescription";
import {FormattedMessage, useIntl} from "react-intl";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import {DividingHR} from "../../../Utils/DividingHR";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CustomerFilterAccordeon from "./CustomerFilterAccordeon";
import {CustomerItemReferencingType} from "../../../../../secondary/InMemory/Data/enums";
import {getCustomerSpecificData} from "../../../../../../store/selectors/serverSelector";
import {InvoiceFilterImpl} from "../../../../../../corelogic/models/invoice";

const CustomerListPage = () => {
    const dispatch = useDispatch()
    const {data, fetching, count} = useSelector(getCustomersSelector)
    const {dataFormFields} = useSelector(getCustomerFormSelector)
    const {customerSpecificData} = useSelector(getCustomerSpecificData)
    const navigation = useNavigate()
    const intl = useIntl()
    const [density, setDensity] = useState<boolean>(false)
    const [toggleFilters, setToggleFilters] = useState<boolean>(false)
    const [allowDeleteAction, setAllowDeleteAction] = useState<boolean>(false)
    const [idsCheckbox, setIdsCheckbox] = useState<GridRowSelectionModel>([])
    const [customerFilter, setCustomerFilter] = useState<CustomerFilter>({
        search: "",
        block: false,
        ca: [],
        sf: [],
        fa: [],
        iv: undefined,
        zc: undefined,
        rp: undefined,
        va: [],
        re: [],
        ra: undefined
    })
    const [idForDeletion, setIdForDeletion] = useState<null | number>(null)
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 25,
        page: 0,
    })
    const [filterCount, setFilterCount] = useState(1)
    const [searchParams, setSearchParams] = useSearchParams();
    const columns: GridColDef[] = [
        ...getColumnsCustomersListDescription(customerSpecificData?.customerSpecificCode).map(cd => ({
            ...cd,
            headerName: intl.formatMessage(getMessageDescriptor(cd.headerName)),
            description: intl.formatMessage(getMessageDescriptor(cd.headerName))
        })),
        {
            field: 'actions',
            type: 'actions',
            headerName: 'bic',
            width: 150,
            headerAlign: "center",
            sortable: false,
            editable: false,
            filterable: false,
            disableReorder: true,
            resizable: false,
            hideable: false,
            renderHeader: () => {
                return (
                    <Box sx={{width: "100%", height: "100%"}}>
                        <Tooltip title="Rafraîchir le tableau">
                            <IconButton onClick={handleRefresh}
                                        sx={{
                                            fontSize: "1.5rem",
                                            cursor: "pointer"
                                        }}>
                                <RefreshIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Changer la densité">
                            <IconButton onClick={handleChangeDensity}
                                        sx={{
                                            fontSize: "1.5rem",
                                            cursor: "pointer"
                                        }}>
                                <ReorderIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )
            },
            renderCell: (params) => {
                return (
                    <Box sx={{display: "flex", justifyContent: "space-between"}}>
                        <Tooltip title="Editer ce client">
                            <IconButton onClick={() => navigation(`/customers/${params.row.id}`)}
                                        sx={{
                                            fontSize: "1.5rem",
                                            cursor: "pointer"
                                        }}>
                                <EditIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Gérer referecement article">
                            <IconButton
                                onClick={() => navigation(`/customers/referencing?type=${CustomerItemReferencingType.REFERENCING_BY_CUSTOMER}&id=${params.row.id}`)}
                                sx={{
                                    fontSize: "1.5rem",
                                    cursor: "pointer"
                                }}>
                                <AccountTreeIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Supprimer ce client">
                            <IconButton onClick={() => handleDeleteCustomerClick(params)}
                                        sx={{
                                            fontSize: "1.5rem",
                                            cursor: "pointer"
                                        }}>
                                <DeleteIcon sx={{fill: "rgba(255, 82, 82, 1)"}}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )
            }
        }
    ]

    useEffect(() => {
        return () => dispatch<any>({type: APP_ACTIONS_TYPES.customers.CUSTOMER_RESET_DATA})
    }, [dispatch])

    useEffect(() => {
        if (searchParams.size === 0) {
            dispatch(retrieveAllCustomersFiltered(customerFilter, {
                page: customerFilter.page,
                size: customerFilter.size
            }))
            return
        }
        let params = CustomerFilterImpl.fromSearchParam(searchParams)
        setCustomerFilter(params.getFilters())
        setPaginationModel(
            {
                pageSize: params.size ? params.size : 25,
                page: params.page ? params.page - 1 : 0
            }
        )
        setFilterCount(params.count())
        dispatch(retrieveAllCustomersFiltered(params.getFilters(), {
            size: params.size ? params.size : 25,
            page: params.page ? params.page : 1
        }))
    }, [searchParams, dispatch])

    useEffect(() => {
        dispatch(retrieveCustomerFormDataValues())
    }, [dispatch])

    const handlePaginationChange = (newPage: number, newPageSize: number) => {
        setPaginationModel(() => ({
            pageSize: newPageSize,
            page: newPage,
        }))
        let filters: CustomerFilter = {
            ...customerFilter,
            size: newPageSize,
            page: newPage + 1
        }
        setCustomerFilter({
            ...filters,
        })
        const filtersImpl = new CustomerFilterImpl(filters);
        setSearchParams(filtersImpl.toSearchParam().toString())
    }

    const handleDeleteCustomerClick = (params: GridRenderCellParams) => {
        setIdForDeletion(params.row.id)
    }

    const handleRefresh = () => {
        dispatch<any>(retrieveAllCustomersFiltered(customerFilter, {
            size: paginationModel.pageSize,
            page: paginationModel.page + 1
        }))
    }

    const handleRowDoubleClick = (params: GridRowParams) => {
        navigation(`/customers/${params.row.id}`)
    }

    const handleChangeSearchInput = useCallback((inputValue: string) => {
        if (inputValue !== customerFilter.search) {
            setCustomerFilter(prev => ({...prev, search: inputValue}))
            setSearchParams(new CustomerFilterImpl({...customerFilter, search: inputValue}).toSearchParam().toString())
        }
    }, [customerFilter, setSearchParams, setCustomerFilter]);

    const handleDeleteCustomer = () => {
        const id = Number(idForDeletion)
        if (!isNaN(id))
            dispatch(deleteCustomer(id))
        setIdForDeletion(null)
    }

    const handleCloseDialogCustomerDeletion = () => {
        setIdForDeletion(null)
    }

    const handleChangeDensity = () => {
        setDensity(prevState => !prevState)
        if (!density && paginationModel.pageSize <= 50) {
            setPaginationModel({...paginationModel, pageSize: 50})
        }
    }

    const handleRowSelection = (ids: GridRowSelectionModel) => {
        if (ids.length > 0) {
            setAllowDeleteAction(true)
            setIdsCheckbox(ids)
        } else {
            setAllowDeleteAction(false)
            setIdsCheckbox([])
        }
    }

    const handleApplyAllFilters = (filters: CustomerFilter, filterCount: number) => {
        let filtersUpdate: CustomerFilter = {
            ...customerFilter,
            block: filters.block,
            si: filters.si === "" ? undefined : filters.si,
            iv: filters.iv === "" ? undefined : filters.iv,
            zc: filters.zc === "" ? undefined : filters.zc,
            rp: filters.rp === "" ? undefined : filters.rp,
            ra: filters.ra === "" ? undefined : filters.ra,
            ca: filters.ca,
            sf: filters.sf,
            fa: filters.fa,
            re: filters.re,
            va: filters.va,
        }
        setCustomerFilter(filtersUpdate)
        const filtersImpl = new CustomerFilterImpl(filtersUpdate);
        setSearchParams(filtersImpl.toSearchParam().toString())
        setFilterCount(filterCount)
        setPaginationModel(prev => ({
            pageSize: prev.pageSize,
            page: 0,
        }))
    }

    const handleResetAllFilters = () => {
        setCustomerFilter({
            ...customerFilter,
            block: false,
            ca: [],
            sf: [],
            fa: [],
            iv: undefined,
            rp: undefined,
            zc: undefined,
            va: [],
            re: [],
            ra: undefined,
            si: undefined
        })
        const filtersImpl = new InvoiceFilterImpl({size: paginationModel.pageSize, page: 1});
        setSearchParams(filtersImpl.toSearchParam().toString())
        setFilterCount(1)
        setPaginationModel(prev => ({
            pageSize: prev.pageSize,
            page: 0,
        }))
    }
    const handleToggleFilterClick = (state: boolean) => {
        setToggleFilters(state)
    }

    return (
        <>
            <Box sx={{position: "sticky", top: "77px", zIndex: 1000}}>
                <CustomerHeader dataClient={null} counterClient={count} filterCount={filterCount}
                                onChangeSearchInput={handleChangeSearchInput}
                                getToggleFiltersState={handleToggleFilterClick}/>
            </Box>
            <Grid container height="84vh" p={1}>
                <Collapse orientation="horizontal" in={toggleFilters} sx={{borderRadius: "1%"}}>
                    <CustomerFilterAccordeon dataFormFields={dataFormFields}
                                             onClickApplyAllFilters={handleApplyAllFilters}
                                             onClickResetAllFilters={handleResetAllFilters}/>
                </Collapse>
                {/*<Grid item md height="inherit">*/}
                {/*    <Paper sx={{height: "100%"}}>*/}
                <DataGrid
                    rows={data}
                    columns={columns}
                    rowCount={count}
                    paginationMode="server"
                    columnVisibilityModel={{id: false}}
                    paginationModel={paginationModel}
                    pageSizeOptions={density ? [50, 75, 100] : [25, 50, 75, 100]}
                    onPaginationModelChange={(newPaginationModel) => handlePaginationChange(newPaginationModel.page, newPaginationModel.pageSize)}
                    onRowSelectionModelChange={handleRowSelection}
                    disableRowSelectionOnClick
                    onRowDoubleClick={handleRowDoubleClick}
                    density={density ? "compact" : "standard"}
                    loading={fetching}
                    slots={{
                        // Toolbar: GridToolbar,
                        noRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                {!fetching && <Alert severity="info">
                                    {intl.formatMessage(getMessageDescriptor("customerListNoData"))}
                                </Alert>}
                            </Stack>
                        )
                    }}
                    sx={{
                        "& .MuiDataGrid-cell:focus": {
                            outline: "none"
                        },
                        "& .MuiDataGrid-columnHeader:focus": {
                            outline: "none"
                        }
                    }}/>
                {/*</Paper>*/}
                {/*</Grid>*/}
            </Grid>
            <Dialog
                maxWidth="lg"
                open={idForDeletion !== null}
            >
                <DialogTitle>
                    <FormattedMessage id={messages.customerGridCustomerDeletion.id}/>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText m={1}>
                        <Alert
                            severity="warning">{intl.formatMessage(getMessageDescriptor("customerGridCustomerDeletionConfirm"))}</Alert>
                    </DialogContentText>
                    <DialogContentText m={1}>
                        <Alert
                            severity="info">{intl.formatMessage(getMessageDescriptor("customerGridCustomerDeletionCancel"))}</Alert>
                    </DialogContentText>
                </DialogContent>
                <DividingHR subContent/>
                <DialogActions>
                    <Button variant="outlined" color="error"
                            onClick={handleCloseDialogCustomerDeletion}><FormattedMessage
                        id={messages.genericCancel.id}/></Button>
                    <Button variant="outlined" onClick={handleDeleteCustomer}><FormattedMessage
                        id={messages.genericConfirm.id}/></Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CustomerListPage;